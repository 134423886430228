/** @file Find components on the page and initialize them.
 */
import AEM from './aem.js';
import 'base/js/templates/general/safari-focus-polyfill.js';
import { Tabs } from 'Components/tabs/clientlibs/js/tabs.class.js';
import { Forms } from './templates/general/form.js';
import { loadScript } from './templates/general/loadScript';
import { AnchorBar } from 'Components/anchorBar/clientlib/js/anchorBar.class.js';
import { Header } from 'Components/structureComponents/header/header/clientlibs/site/js/header.class.js';
import { SearchBox } from 'Components/search/searchBox/clientlibs/js/searchBox.class.js';
import { Footnote } from 'Components/structureComponents/footnotes/clientlibs/js/footnotes.class.js';
import { Footer } from 'Components/structureComponents/footer/footer/clientlibs/js/footer.class.js';
import { Text } from 'Components/text/clientlibs/js/text.class.js';
import { Accordion } from 'Components/accordion/clientlibs/site/js/accordion.class.js';
import { HeaderMenu } from 'Components/structureComponents/header/headerMenu/clientlibs/site/js/headermenu.class.js';
import { Video } from 'Components/video/clientlibs/js/video.class.js';
import { Container } from 'Components/container/clientlibs/site/js/container.class.js';
import { CountryPromo } from 'Components/countryPromo/clientlibs/site/js/countryPromo.class.js';
import { Events } from 'Components/events/clientlibs/js/events.class.js';
import { Locations } from 'Components/locations/clientlibs/js/locations.class.js';
import { XilinxCart } from 'Components/structureComponents/header/headerIcons/clientlibs/js/cart.class.js';
import { ComparisonChart } from 'Components/comparisonChart/clientlibs/site/js/comparisonChart.class.js';
import { GalleryModal } from 'Components/gallery/clientlibs/site/js/galleryModal.class.js';
import { Navigation } from 'Components/navigation/clientlibs/js/navigation.class.js';
import { AnimatedHotspots } from 'Components/animatedHotspots/clientlibs/site/js/animatedHotspots.class.js';
import { LoadScripts } from 'Components/loadScripts/clientlibs/js/loadScripts.class.js';
import { initMktoSingleton } from 'base/js/templates/general/mktoFormsInstanceManager.js';
import { ImageComparison } from 'Components/imageComparison/clientlibs/js/imageComparison.class.js';
import { showModal } from './templates/general/youtubeModal';
import { Teaser } from 'Components/teaser/clientlibs/js/teaser.class.js';
import { youtubeImage } from 'Components/image/clientlibs/site/js/youtubeImage.class.js';
import { CustomModal } from 'Components/customModal/clientlib/js/customModal.class.js';

const registerComponent = AEM.registerComponent;

/*
 **************************************
 *********** Dynamic Imports ***********
 **************************************
 */

/**
 * Initialize imageModal.
 */
if (document.querySelectorAll('[id^="imageModal-image-"]').length) {
    import(/* webpackChunkName: 'imagemodal' */ 'Components/image/clientlibs/site/js/imageModal.class.js').then(({ imageModal }) => {
        registerComponent('[id^="imageModal-image-"]', imageModal);
    });
}

/**
 * Initialize galleryModal.
 */
if (document.querySelectorAll('[id^="galleryModal-"]').length) {
    import(/* webpackChunkName: 'gallerymodal' */ 'Components/gallery/clientlibs/site/js/galleryModal.class.js').then(({ GalleryModal }) => {
        registerComponent('[id^="galleryModal-"]', GalleryModal);
    });
}

/**
 * Initialize AMD Datatable component.
 */
if (document.querySelectorAll('[data-component="amd-datatable"]').length) {
    import(/* webpackChunkName: 'AMDDatatable' */ 'base/js/templates/general/datatables.js').then(({ AMDDatatable }) => {
        registerComponent('[data-component="amd-datatable"]', AMDDatatable);
    });
}

/**
 * Import Gallery class then initialize each image gallery.
 */
if (document.querySelectorAll('[data-cmp-is="image-gallery"]').length) {
    import(/* webpackChunkName: 'gallery' */
        'Components/gallery/clientlibs/site/js/gallery.class.js').then(({ Gallery }) => {
        registerComponent('[data-cmp-is="image-gallery"]', Gallery);
    });
}

/**
 * Import logowall class then initialize each logo.
 */
if (document.querySelectorAll('.logoWall').length) {
    import(/* webpackChunkName: 'logowall' */
        'Components/logowall/clientlibs/site/js/logowall.class.js').then(({ logowall }) => {
        registerComponent('.logoWall', logowall);
    });
}

/**
  * Import YouTube class then initialize each YouTube embed.
  * Dynamic import of the YouTubeEmbed class because it has dependencies as well.
  */
if (document.querySelectorAll('[data-cmp-is="youtube-onetrust"]').length) {
    import(/* webpackChunkName: 'youtube' */
        'Components/embed/embeddable/youtube/clientlibs/js/youtubeembed.class.js').then(({ YouTubeEmbed }) => {
        registerComponent('[data-cmp-is="youtube-onetrust"]', YouTubeEmbed);
    });
}

/**
  * Import Brightcove class then initialize each Brightcove embed.
  * Dynamic import of the BrightcoveEmbed class because it has dependencies as well.
  */
if (document.querySelectorAll('[data-cmp-is="brightcove-onetrust"]').length) {
    import(/* webpackChunkName: 'brightcove' */
        'Components/embed/embeddable/brightcove/clientlibs/js/brightcoveembed.class.js').then(({ BrightcoveEmbed }) => {
        registerComponent('[data-cmp-is="brightcove-onetrust"]', BrightcoveEmbed);
    });
}

/**
 * Import markdown renderer.
 */
if (document.querySelectorAll('.markdown-render').length) {
    import('Components/markDownRender/clientlibs/js/markDownRender.class.js').then(({ markDownRender }) => {
        registerComponent('.markdown-render', markDownRender);
    });
}

/**
  * Import CoveoSearch class then initialize each CoveoSearch component.
  * Dynamic import of the CoveoSearch class because it has dependencies as well.
  */
if (document.querySelectorAll('[data-component="coveo-search"]').length) {
    import(/* webpackChunkName: 'searchlayout' */
        'Components/search/searchLayout/clientlibs/site/js/searchLayout.class.js').then(({ CoveoSearch }) => {
        registerComponent('[data-component="coveo-search"]', CoveoSearch);
    });
}

/**
  * Import CoveoXFTemplate class then initialize each xfsearch template.
  * Dynamic import of the CoveoXFTemplate class because it has dependencies as well.
  */
if (document.querySelectorAll('[data-component="search-xf-template"]').length) {
    import(/* webpackChunkName: 'searchxf' */
        'Components/structure/xfsearch/clientlibs/js/search.template.class.js').then(({ CoveoXFTemplate }) => {
        registerComponent('[data-component="search-xf-template"]', CoveoXFTemplate);
    });
}

/**
  * Import SolutionSelector class then initialize each Solution Selector component.
  * Dynamic import of the SolutionSelector class because it has dependencies as well.
  */
const SolutionSelectorCarousels = document.querySelectorAll('[data-cmp-is="solutionselector"]');
if (SolutionSelectorCarousels.length) {
    import(/* webpackChunkName: 'solutionselector' */
        'Components/solutionSelector/clientlibs/site/js/solutionSelector.class.js').then(({ SolutionSelector }) => {
        registerComponent(SolutionSelectorCarousels, SolutionSelector);
    });
}

/**
  * Import ImageThumbnailCarousel class then initialize each Image Thumbnail Carousel component.
  * Dynamic import of the ImageThumbnailCarousel class because it has dependencies as well.
  */
const ImageThumbnailCarousels = document.querySelectorAll('[data-cmp-is="imagethumbnailcarousel"]');
if (ImageThumbnailCarousels.length) {
    import(/* webpackChunkName: 'imagethumbnailcarousel' */
        'Components/imageThumbnailCarousel/clientlibs/site/js/imageThumbnailCarousel.class.js').then(({ ImageThumbnailCarousel }) => {
        registerComponent(ImageThumbnailCarousels, ImageThumbnailCarousel);
    });
}

/**
 * Import HeroCarousel class if there are any Hero Carousels on the page.
 * @type {NodeListOf<Element>}
 */
const HeroCarousels = document.querySelectorAll('[data-cmp-is="herocarousel"]');
if (HeroCarousels.length) {
    import (/* webpackChunkName: 'herocarousel' */
        'Components/heroCarousel/clientlibs/site/js/heroCarousel.class.js').then(({ HeroCarousel }) => {
        registerComponent(HeroCarousels, HeroCarousel);
    });
}

/**
  * Import MarketoFormEmbed class then initialize each MarketoFormEmbed embed.
  * Dynamic import of the MarketoFormEmbed
  */

/* Prevent Marketo forms to re-render when same formId is used on the page multiple times.
  * Only laods the first one
  */
const marketoForms = [ ...document.querySelectorAll('[data-component="cmp-marketoform"]') ];
const formIds = new Set(marketoForms.map(form => form.dataset.formId));
const domains = new Set(marketoForms.filter(form => form.dataset.marketoFormDomain).map(form => `//${form.dataset.marketoFormDomain}/js/forms2/js/forms2.min.js`));

marketoForms.forEach(marketoForm => {
    if (formIds.has(marketoForm.dataset.formId)) {
        formIds.delete(marketoForm.dataset.formId);
    } else {
        marketoForm.dataset.component = '';
        marketoForm.querySelector('form').remove();
    }
});

if (marketoForms.length > 0) {
    initMktoSingleton().then(() => {
        loadScript(Array.from(domains)).then(() => {
            import(/* webpackChunkName: 'marketo' */
                'Components/embed/embeddable/marketoForm/clientlibs/js/marketoForm.class.js').then(({ MarketoFormEmbed }) => {
                registerComponent('[data-component="cmp-marketoform"]', MarketoFormEmbed);
            });
        });
    });
}

/**
  * Import Carousel class then initialize each Carousel.
  */
const cmpCarousels = document.querySelectorAll('[data-component="cmp-carousel"]');
if (cmpCarousels.length) {
    // Add an index for each carousel
    cmpCarousels.forEach((cmpCarousel, index) => {
        cmpCarousel.dataset.trackingIndex = `cmp-carousel-${index}`;
    });
    import(/* webpackChunkName: 'carousel' */
        'Components/carousel/clientlibs/site/js/carousel.class.js').then(({ Carousel }) => {
        registerComponent('[data-component="cmp-carousel"]', Carousel);
    });
}

/**
  * Import Blogs class and initialize it.
  */
if (document.querySelectorAll('[data-cmp-is="blog-list"]').length) {
    import(/* webpackChunkName: 'bloglist' */
        'Components/bloglist/clientlibs/js/bloglist.class.js').then(({ BlogList }) => {
        registerComponent(document.querySelectorAll('[data-cmp-is="blog-list"]'), BlogList);
    });
}

/**
  * Import Current Href class and initialize it.
  */
if (document.querySelectorAll('[data-cmp-is="current-href"]').length) {
    import(/* webpackChunkName: 'currentHref' */
        'Components/form/currentHref/clientlib/js/currentHref.class.js').then(({ CurrentHref }) => {
        registerComponent(document.querySelectorAll('[data-cmp-is="current-href"]'), CurrentHref);
    });
}

/**
 * Initialize remove class.
 */
const heroImages = document.querySelectorAll('.hero-content img');
heroImages.forEach(heroImage => {
    heroImage.classList.remove('cmp-image__image');
});

/**
 * Wrap underscores in title with cursor class.
 */
const titleCursors = document.querySelectorAll('.title-cursor');
if (titleCursors.length) {
    titleCursors.forEach(titleCursor => {
        const titles = titleCursor.querySelectorAll('.cmp-title .cmp-title__text, .hero-content h1, .hero-content .pre-title, .hero-content .sub-title, .carousel-title, .carousel-text');
        if (titles.length) {
            titles.forEach(title => {
                title.childNodes.forEach(child => {
                    if (child.nodeName === '#text' && child.childNodes.length === 0 && child.textContent.includes('_')) {
                        let childText = child.textContent.split('_');
                        let blinker = '<span class="cursor">_</span>';
                        const childSpan = document.createElement('span');
                        childSpan.innerHTML = childText.join(blinker);
                        child.replaceWith(childSpan);
                    }
                });
            });
        }
    });
}

/**
 * Function to replace `><` with an icon span within a given element.
 */
function replaceTextWithIcon(element) {
    element.childNodes.forEach(child => {
    	if (child.nodeType === Node.TEXT_NODE && child.textContent.includes('><')) {
        	const textParts = child.textContent.split('><');
        	const fragment = document.createDocumentFragment();

        	textParts.forEach((text, index) => {
            	if (text) {
                	fragment.appendChild(document.createTextNode(text));
            	}
            	if (index < textParts.length - 1) {
                	const iconSpan = document.createElement('span');
                	iconSpan.className = 'icon-x';
                	fragment.appendChild(iconSpan);
            	}
        	});

        	child.replaceWith(fragment);
    	}
    });
}


/**
 * Function to replace all instances of `><` with an icon span in specified elements or in `.icon-placeholder`.
 */
function replaceIconsInElements() {
    const configurations = [
    	{ container: '.cmp-text', selectors: [ '.icon-placeholder' ] },
    	{ container: '.icon-placeholder', selectors: [ '.cmp-title__text' ] },
    	{ container: '.heroExpressive.icon-placeholder', selectors: [ '.title', 'h1', '.pre-title', '.sub-title', '.disclaimer' ] },
    	{ container: '.carouselslide.icon-placeholder', selectors: [ '.carousel-title', '.carousel-text', 'sup' ] }
    ];

    configurations.forEach(config => {
    	document.querySelectorAll(config.container).forEach(container => {
        	config.selectors.forEach(selector => {
            	container.querySelectorAll(selector).forEach(element => {
                	replaceTextWithIcon(element);
            	});
        	});
    	});
    });

    document.querySelectorAll('.icon-placeholder').forEach(element => {
    	replaceTextWithIcon(element);
    });
}

replaceIconsInElements();

/**
  * Import DynamicOptions class then initialize each checkbox, radio, dropdown.
  * Dynamic import of the DynamicOptions class.
  */
if (document.querySelectorAll('[data-component="cmp-dynamic-options"]').length) {
    import('Components/form/dynamicOptions/clientlib/js/dynamicOptions.class.js').then(({ DynamicOptions }) => {
        registerComponent('[data-component="cmp-dynamic-options"]', DynamicOptions);
    });
}

/**
 * Add event listener to YouTube modal links.
 */
const ytModalLinks = document.querySelectorAll('[data-bs-video-modal="video-embed-modal"]');
if (ytModalLinks.length) {
    const params = new URLSearchParams(window.location.search);
    const videoID = params.has('watch') ? params.get('watch') : '';
    ytModalLinks.forEach(link => {
        let embedVideoId = link.dataset.brightcoveVideoId ? link.dataset.brightcoveVideoUrl : `https://www.youtube.com/embed/${link.dataset.bsYtVideoId}`;
        if (!link.href) {
            link.href = embedVideoId;
        }
        link.addEventListener('click', e => {
            e.preventDefault();
            showModal(link);
        });

        if (videoID !== '' && (link.id === videoID || link.closest(`#${videoID}`))) {
            window.setTimeout(() => showModal(link), 100);
        }
    });
}

/**
 * Based on bootstrap doc: Tooltips are opt-in for performance reasons, so you must initialize them yourself.
 * Use dynamic import to conditionally import Tooltip
 */
const bootstrap5TooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
if (bootstrap5TooltipTriggerList.length) {
    import('bootstrap').then(({ Tooltip }) => {
        bootstrap5TooltipTriggerList.map(tooltipTriggerEl => {
		  return new Tooltip(tooltipTriggerEl);
        });
    });
}

/*
 **************************************
 *********** Static Imports ***********
 **************************************
 */

/**
  * Initialize tabs component.
  */
registerComponent('[data-component="tabs"]', Tabs);

/**
  * Disables submit after first submit on ALL forms.
  */
registerComponent('form', Forms);

/**
  * Initialize anchor bar component.
  */
registerComponent('[data-component="anchor-bar"]', AnchorBar);

/**
  * Initialize anchor bar component.
  */
registerComponent('header', HeaderMenu);


/**
  * Initialize footnotes component.
  */
const footnotes = document.querySelectorAll('.footnotes details');
registerComponent(footnotes, Footnote);

/**
 * Initializes footer component.
 */
registerComponent('footer', Footer);

/**
  * Initialize Header component.
  */
registerComponent('[data-component="header"]', Header);

/**
  * Initialize header search box and Search box component
  */
registerComponent('[data-component="header-searchbox"]', SearchBox);
registerComponent('[data-component="search-box"]', SearchBox);

/**
 * Initialize text component.
 */
registerComponent('.cmp-text', Text);

/**
 * Initialize Accordion component.
 */
registerComponent('.bs-accordion', Accordion);

/**
 * Initialize video component.
 */
registerComponent('[data-component="cmp-video"]', Video);

/**
 * Initialize container component.
 */
registerComponent('[data-component="cmp-container"]', Container);

/**
 * Initialize country promos.
 */
registerComponent('.cmp-countrypromo', CountryPromo);

/**
 * Initialize events feed.
 */
registerComponent('.events', Events);

/**
 * Initialize locations feed.
 */
registerComponent('.locations', Locations);

/**
 * Initialize Xilinx Cart.
 */
registerComponent('[data-component="shopping-cart"]', XilinxCart);

/**
 * Initialize Comparison Chart.
 */
registerComponent('[data-component="comparison-chart"]', ComparisonChart);

/**
 * Initialize Gallery Modal.
 */
registerComponent('.gallery-container', GalleryModal);

/**
 * Initialize Navigation.
 */
registerComponent('.cmp-navigation', Navigation);

/**
 * Initialize Animated Hotspots
 */
registerComponent('.hotspots-container', AnimatedHotspots);

/**
 * Initialize Load Custom JS.
 */
registerComponent('.cmp-loadScripts', LoadScripts);

/**
 * Initialize Image Comparison JS.
 */
registerComponent('.image-comparison', ImageComparison);

/**
 * Initialize Teaser component.
 */
registerComponent('.cmp-teaser', Teaser);

/**
 * Initialize Image JS.
 */
registerComponent('.cmp-image', youtubeImage);

/**
 * Initialize Custom Modal.
 */
registerComponent('.customModal', CustomModal);
